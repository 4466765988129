<script setup>
import AppInputTextLabelInside from '@/components/input/AppInputTextLabelInside.vue';
import { defineProps, defineEmits, computed, reactive, onMounted } from 'vue';

const props = defineProps({
    modelValue: Array
});

const emit = defineEmits(['update:modelValue']);

const deProps = computed(() => {
    const index = props.modelValue.findIndex?.((opcao) => opcao?.startsWith?.('Outros'));
    const valor = props.modelValue[index];

    return {
        opcaoOutros: {
            index,
            valor
        },
        checkboxVal: valor ?? 'Outros'
    };
});

const _checkboxModel = computed({
    get: () => props.modelValue.slice(),
    set: () => {
        if (!deProps.value?.opcaoOutros?.valor) {
            emitirAddOpcaoOutros();
        } else {
            emitirRemoveOpcaoOutros();
        }
    }
});

const local = reactive({
    rotulo: 'Outros',
    textoOpcao: ''
});

onMounted(() => {
    const opcaoOutros = deProps.value.opcaoOutros.index;
    const { digitado } = obterDigitado(_checkboxModel.value[opcaoOutros]);
    local.rotulo = _checkboxModel.value ? 'Outros' : '';
    local.textoOpcao = digitado;
});

function obterDigitado(opcaoComLabel) {
    if (!opcaoComLabel) return { rotulo: '', digitado: '' };
    const [lbl, sobre] = opcaoComLabel.split(':');
    const rotulo = `${lbl}:`;
    return { rotulo, digitado: sobre?.trim?.() ?? '' };
}

const textoOpcao = computed({
    get: () => {
        const opcaoOutros = deProps.value.opcaoOutros.index;
        const { digitado } = obterDigitado(_checkboxModel.value[opcaoOutros]);
        return `${digitado}`;
    },
    set: (digitou) => {
        local.textoOpcao = digitou;
        emitirAddOpcaoOutros();
    }
});

const opcaoNova = computed(() => (local.textoOpcao ? `${local.rotulo}:${local.textoOpcao}` : local.rotulo));

function emitirAddOpcaoOutros() {
    const opcaoOutros = deProps.value.opcaoOutros.index;

    const jaIncluido = opcaoOutros !== -1;
    if (jaIncluido) {
        _checkboxModel.value[opcaoOutros] = opcaoNova.value;
    } else {
        _checkboxModel.value.push(opcaoNova.value);
    }

    emit('update:modelValue', _checkboxModel.value);
}

function emitirRemoveOpcaoOutros() {
    const opcaoOutros = deProps.value.opcaoOutros.index;
    const model = _checkboxModel.value.slice();
    if (opcaoOutros !== -1) {
        model.splice(opcaoOutros, 1);
    }
    emit('update:modelValue', model);
}
</script>

<template>
    <div class="flex justify-content-center align-items-center">
        <Checkbox v-bind="$attrs" v-model="_checkboxModel" :value="deProps.checkboxVal" class="pb-1 m-1" />
        <AppInputTextLabelInside :labelText="local.rotulo" :labelFor="local.rotulo" v-model="textoOpcao" unstyled :comBorda="!!_checkboxModel" />
    </div>
</template>
