<script setup>
import { defineProps, defineEmits, computed } from 'vue';
const props = defineProps({
    tipo: String,
    descricao: String,
    ordem: Number,
    opcoes: {
        type: Array,
        default: () => [],
        required: false
    },
    obrigatoria: {
        type: Boolean,
        default: false
    },
    modelValue: String
});

const emits = defineEmits(['update:modelValue']);
const localRespostaNumeroInteiro = computed({
    get: () => Number(props.modelValue),
    set: (newValue) => {
        emits('update:modelValue', newValue);
    }
});

function updateValue(value) {
    localRespostaNumeroInteiro.value = String(value);
}
</script>

<template>
    <div v-if="tipo === 'IN'" class="flex flex-column align-items-start p-0">
        <label class="flex gap-1 p-text-center font-medium text-lg mb-3">
            <span>{{ ordem }} - </span>
            <span>{{ descricao }} </span>
            <span v-if="obrigatoria" class="p-error">*</span>
        </label>
        <InputNumber :modelValue="localRespostaNumeroInteiro" @update:modelValue="updateValue" :showButtons="true" />
    </div>
</template>
