<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue';

const props = defineProps({
    modelValue: String,
    labelFor: String,
    labelText: String,
    comBorda: {
        type: Boolean,
        default: true
    }
});

const emit = defineEmits(['update:modelValue', 'blur']);

const _digitando = computed({
    get() {
        return props.modelValue;
    },
    set(digitado) {
        emit('update:modelValue', digitado);
    }
});
const inputSelected = ref(false);
</script>

<template>
    <label
        :id="labelFor"
        :for="labelFor"
        :class="{ 'p-my-input-with-shadow': inputSelected, 'p-my-input-with-border': comBorda }"
        class="flex gap-2 p-inputtext p-component p-my-input"
    >
        <span>{{ labelText }} </span>
        <InputText
            v-bind="$attrs"
            v-model="_digitando"
            @blur="emit('blur', _digitando)"
            class="border-none outline-none p-my-input-model mb-10"
            @focusin="inputSelected = true"
            @focusout="inputSelected = false"
        />
    </label>
</template>

<style scoped lang="scss">
.p-my-input {
    border: none;
    padding: 4px;
}
.p-my-input-with-shadow {
    box-shadow: 0 2px 0.1rem #91dcf7;
}
.p-my-input-with-border {
    border-bottom: 1px solid #ced4da;
}
.p-my-input-model {
    color: #495057;
    font-size: 0.9rem;
}
</style>
