export function useFocusElement() {
    return {
        byId: (identificacao) => {
            const element = document.getElementById(identificacao);
            focus(element);
        },
        byClassName: (identificacao) => {
            const elements = document.getElementsByClassName(identificacao);
            focus(elements[0]);
        }
    };
}

function focus(element) {
    if (!element) return;

    if (element.childNodes.length > 1) {
        element.firstElementChild.children[0].focus();
        return;
    }

    element.focus();
}
