<script setup>
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
    tipo: String,
    descricao: String,
    ordem: Number,
    opcoes: {
        type: Array,
        default: () => [],
        required: false
    },
    obrigatoria: {
        type: Boolean,
        default: false
    },
    isInvalid: {
        type: Boolean,
        default: false
    },
    elementId: {
        type: String,
        default: ''
    },
    modelValue: [String, Number]
});

const emits = defineEmits(['update:modelValue']);
const localRespostaDissertativa = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        emits('update:modelValue', newValue);
    }
});
</script>

<template>
    <div v-if="tipo === 'DI'" class="flex flex-column align-items-start p-0">
        <label class="flex gap-1 p-text-center font-medium text-lg mb-3">
            <span>{{ ordem }} - </span>
            <span>{{ descricao }} </span>
            <span v-if="obrigatoria" class="p-error">*</span>
        </label>
        <InputText :id="elementId" v-model.trim="localRespostaDissertativa" class="w-full" :class="{ 'p-invalid': isInvalid }" />
    </div>
</template>
